import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const SelectIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.29456 8.90145C6.90403 9.29198 6.90403 9.92514 7.29456 10.3157C7.68508 10.7062 8.31825 10.7062 8.70877 10.3157L7.29456 8.90145ZM11.6102 6L12.3173 5.29289C11.9268 4.90237 11.2936 4.90237 10.9031 5.29289L11.6102 6ZM14.5117 10.3157C14.9022 10.7062 15.5354 10.7062 15.9259 10.3157C16.3164 9.92514 16.3164 9.29198 15.9259 8.90145L14.5117 10.3157ZM15.9242 15.7285C16.3148 15.338 16.3148 14.7048 15.9242 14.3143C15.5337 13.9238 14.9005 13.9238 14.51 14.3143L15.9242 15.7285ZM11.6086 18.63L10.9015 19.3371C11.292 19.7276 11.9251 19.7276 12.3157 19.3371L11.6086 18.63ZM8.70711 14.3143C8.31658 13.9238 7.68342 13.9238 7.29289 14.3143C6.90237 14.7048 6.90237 15.338 7.29289 15.7285L8.70711 14.3143ZM8.70877 10.3157L12.3173 6.70711L10.9031 5.29289L7.29456 8.90145L8.70877 10.3157ZM10.9031 6.70711L14.5117 10.3157L15.9259 8.90145L12.3173 5.29289L10.9031 6.70711ZM14.51 14.3143L10.9015 17.9229L12.3157 19.3371L15.9242 15.7285L14.51 14.3143ZM12.3157 17.9229L8.70711 14.3143L7.29289 15.7285L10.9015 19.3371L12.3157 17.9229Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SelectIcon;
